/**
 * @created by Zheko Hristov on 17/08/2021
 * @last-updated by Zheko Hristov on 03/11/2021
 *
 * Order form validate scripts
 **/

import Alerts from './alerts-scripts';

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        // basic regex patterns for input and textarea validation, based on the field id
        const regexInputPatterns = {
            user_name: /^[a-zа-я]{1}[a-zа-я\s-]*$/i,
            user_telephone: /^[\+]?\d{10,}$/,
            user_email: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
            // user_city: /^[a-zа-я]{1}[a-zа-я\s]+$/i,
            user_city: /\S+/,
            user_address: /\S+/,
        };

        // validation function with regex
        // for toggling 'field--valid' and 'field--valid' classes on the fields
        function validateField(field, regex) {
            // if there is econt validation do not use our validation
            if (field.classList.contains('container-with-econt-validation')) {
                return;
            }
            if (regex.test(field.value)) {
                field.classList.remove('field--invalid');
                field.classList.add('field--valid');
            } else {
                field.classList.remove('field--valid');
                field.classList.add('field--invalid');
            }
        }

        // form submit button
        const orderFormSubmitButton = document.querySelector('.form-input__button--form-submit');

        // inputs and textareas checks
        const validateInputElements = document.querySelectorAll('.input--validate');
        const validateInputElementsLength = validateInputElements.length;
        for (let i = 0; i < validateInputElementsLength; i++) {
            validateInputElements[i].addEventListener('input', function (e) {
                validateField(e.target, regexInputPatterns[e.target.dataset.validateType]);
            });
            validateInputElements[i].addEventListener('input', enableaDisableSubmitButton);
        }

        // checkboxes checks
        const checkboxInputs = document.querySelectorAll(
            '.form-input__container--right.checkbox__container input[type=checkbox]'
        );
        const checkboxInputsLength = checkboxInputs.length;
        for (let i = 0; i < checkboxInputsLength; i++) {
            checkboxInputs[i].addEventListener('change', enableaDisableSubmitButton);
        }

        bindEcontEvents();

        // econt validation function
        function bindEcontEvents() {
            // autofocus the search field on click
            $(document).on('focus', '.select2-selection', function (e) {
                let element = document.querySelector('.select2-search__field');
                if (element !== undefined && element !== null) {
                    element.focus();
                }
            });

            const form = $('#new-order');
            const autocompleteOfficeCity = $('#order-econt-office-city-autocomplete');
            const autocompleteOffice = $('#order-econt-office-autocomplete');
            const autocompleteAddressCity = $('#order-econt-address-city-autocomplete');
            const econtDeliveryAddressInput = $('#order-econt-delivery-address');
            const deliveryMethodSelect = $('.order-delivery-method');
            const deliveryPriceInfo = $('#order-delivery-price-info');
            const deliveryPriceInput = $('#order-delivery-price');

            let selectedDeliveryMethod = $('.order-delivery-method:checked').val();
            let selectedEcontOfficeCityId = autocompleteOfficeCity.val();
            let selectedEcontAddressCityId = autocompleteAddressCity.val();
            let econtDeliveryAddress = econtDeliveryAddressInput.val();

            selectDeliveryMethod();
            // select method for delivery function
            function selectDeliveryMethod() {
                $('.form-input__container--outer[data-delivery-method]').addClass('hidden');
                $(
                    '.form-input__container--outer[data-delivery-method="' +
                        selectedDeliveryMethod +
                        '"]'
                ).removeClass('hidden');

                $('input[data-delivery-method]').attr('disabled', true);
                $('select[data-delivery-method]').attr('disabled', true);

                $('input[data-delivery-method="' + selectedDeliveryMethod + '"]').attr(
                    'disabled',
                    false
                );
                $('select[data-delivery-method="' + selectedDeliveryMethod + '"]').attr(
                    'disabled',
                    false
                );
            }

            deliveryMethodSelect.on('change', function () {
                selectedDeliveryMethod = $(this).val();
                selectDeliveryMethod();
                calculateDeliveryPrice();
                enableaDisableSubmitButton();
            });

            function checkIfBlank(val) {
                return val == undefined || val == null || val.length < 1;
            }

            // Autocomplete city for econt office
            autocompleteOfficeCity.select2({
                minimumInputLength: 0,
                language: 'bg',
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            has_active_offices: true,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/cities';
                    },
                },
                width: 'resolve',
            });

            // Econt office autocomplete
            autocompleteOffice.select2({
                minimumInputLength: 0,
                language: 'bg',
                minimumResultsForSearch: 15,
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            city_id: selectedEcontOfficeCityId,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/offices';
                    },
                },
                width: 'resolve',
            });

            autocompleteOfficeCity.on('select2:select', function (e) {
                let data = e.params.data;

                if (selectedEcontOfficeCityId != data.id) {
                    // reset the office select
                    autocompleteOffice.val(null).trigger('change');
                }

                selectedEcontOfficeCityId = data.id;

                if (checkIfBlank(selectedEcontOfficeCityId)) {
                    autocompleteOfficeCity.addClass('field--invalid');
                    autocompleteOfficeCity.removeClass('field--valid');
                } else {
                    autocompleteOfficeCity.removeClass('field--invalid');
                    autocompleteOfficeCity.addClass('field--valid');
                }
            });

            autocompleteOffice.on('select2:select', function (e) {
                let data = e.params.data;

                if (checkIfBlank(data.id)) {
                    autocompleteOffice.addClass('field--invalid');
                    autocompleteOffice.removeClass('field--valid');
                } else {
                    autocompleteOffice.removeClass('field--invalid');
                    autocompleteOffice.addClass('field--valid');
                }

                calculateDeliveryPrice();

                enableaDisableSubmitButton();
            });

            // Econt city autocomplete - delivery to address option
            autocompleteAddressCity.select2({
                minimumInputLength: 0,
                language: 'bg',
                ajax: {
                    delay: 600,
                    data: function (params) {
                        var query = {
                            term: params.term,
                            page: params.page || 1,
                            has_active_streets: true,
                        };

                        return query;
                    },
                    url: function (params) {
                        // return autocomplete.data('url');
                        return '/econt/cities';
                    },
                },
                width: 'resolve',
            });

            autocompleteAddressCity.on('select2:select', function (e) {
                let data = e.params.data;
                if (selectedEcontAddressCityId != data.id) {
                    selectedEcontAddressCityId = data.id;
                    validateAddress();
                }

                if (checkIfBlank(selectedEcontAddressCityId)) {
                    autocompleteAddressCity.addClass('field--invalid');
                    autocompleteAddressCity.removeClass('field--valid');
                } else {
                    autocompleteAddressCity.removeClass('field--invalid');
                    autocompleteAddressCity.addClass('field--valid');
                }

                enableaDisableSubmitButton();
            });

            econtDeliveryAddressInput.on('input', function () {
                if ($(this).val() != econtDeliveryAddress) {
                    econtDeliveryAddress = $(this).val();
                    // disable the button on start writing
                    orderFormSubmitButton.classList.add('disabled');
                    orderFormSubmitButton.setAttribute('tabindex', '-1');
                    orderFormSubmitButton.setAttribute('disabled', '');
                    debounce(validateAddress, 1000);
                }
            });

            function validateAddress() {
                if (checkIfBlank(econtDeliveryAddress)) {
                    updateEcontDeliveryAddress({});
                    calculateDeliveryPrice();
                }

                if (
                    checkIfBlank(econtDeliveryAddress) ||
                    checkIfBlank(selectedEcontAddressCityId)
                ) {
                    return;
                }

                let url = '/econt/address/validate';

                $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        city_id: selectedEcontAddressCityId,
                        address: econtDeliveryAddress,
                    },
                    dataType: 'json',
                })
                    .done(function (json) {
                        updateEcontDeliveryAddress(json.address);
                        econtDeliveryAddressInput.removeClass('field--invalid');
                        econtDeliveryAddressInput.addClass('field--valid');
                        calculateDeliveryPrice();
                        enableaDisableSubmitButton();
                    })
                    .fail(function (xhr, status, error) {
                        econtDeliveryAddressInput.addClass('field--invalid');
                        econtDeliveryAddressInput.removeClass('field--valid');
                        updateEcontDeliveryAddress({ fullAddress: econtDeliveryAddress });

                        let response = xhr.responseJSON || {
                            error: 'Възникна грешка при валидацията на адреса',
                        };

                        Alerts.renderAlert(response.error, 'error');
                        calculateDeliveryPrice();
                    });
            }

            function calculateDeliveryPrice() {
                if (selectedDeliveryMethod == 'international_dhl') {
                    deliveryPriceInfo.html('Според тарифата на DHL');
                    deliveryPriceInput.val(null);
                    return;
                }

                let formData = form.serialize();

                $.ajax({
                    type: 'POST',
                    url: '/econt/delivery-price/calculate',
                    data: formData, // serializes the form's elements.
                })
                    .done(function (json) {
                        deliveryPriceInfo.html(json.price_text);
                        deliveryPriceInput.val(json.price);
                    })
                    .fail(function (xhr, status, error) {
                        deliveryPriceInfo.html('Според тарифата на Еконт');
                        deliveryPriceInput.val(null);
                    });
            }

            const deliveryAddressStreetInput = $('#order-econt-delivery-address-street');
            const deliveryAddressStreetNumInput = $('#order-econt-delivery-address-street-num');
            const deliveryAddressQuarterInput = $('#order-econt-delivery-address-quarter');
            const deliveryAddressOtherInput = $('#order-econt-delivery-address-other');

            function updateEcontDeliveryAddress(address) {
                econtDeliveryAddressInput.val(address.fullAddress);
                deliveryAddressStreetInput.val(address.street);
                deliveryAddressStreetNumInput.val(address.num);
                deliveryAddressQuarterInput.val(address.quarter);
                deliveryAddressOtherInput.val(address.other);
            }
        }

        // function for enable/disable submit button, based on the validations and checkboxes
        function enableaDisableSubmitButton() {
            const activeInputFields = document.querySelectorAll('.input--validate:not([disabled]');
            const activeInputFieldsLength = activeInputFields.length;
            const validInputFields = document.querySelectorAll(
                '.input--validate.field--valid:not([disabled]'
            );
            const validInputFieldsLength = validInputFields.length;
            const checkedCheckboxes = document.querySelectorAll(
                '.form-input__container--right.checkbox__container input[type=checkbox]:checked'
            );
            const checkedCheckboxesLength = checkedCheckboxes.length;
            // const activeSelectFields = document.querySelectorAll('.input--validate:not([disabled]')

            if (
                validInputFieldsLength === activeInputFieldsLength &&
                checkedCheckboxesLength === checkboxInputsLength
            ) {
                orderFormSubmitButton.classList.remove('disabled');
                orderFormSubmitButton.setAttribute('tabindex', '0');
                orderFormSubmitButton.removeAttribute('disabled');
            } else {
                orderFormSubmitButton.classList.add('disabled');
                orderFormSubmitButton.setAttribute('tabindex', '-1');
                orderFormSubmitButton.setAttribute('disabled', '');
            }
        }

        // simple deboucnce function
        let debounceTimer;

        function debounce(callback, time) {
            window.clearTimeout(debounceTimer);
            debounceTimer = window.setTimeout(callback, time);
        }
    })();
});
