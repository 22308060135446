/**
 * @created by Zheko Hristov on 17/08/2021
 *
 * Main site scripts
 **/

document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 25/02/2022
         * @last-updated by Zheko Hristov on 25/02/2022
         *
         * Close the mobile menu if it is not closed after browser back button clicks
         **/

        if ($('.mobile-menu__container--icons .fa-times').hasClass('showed')) {
            $(
                '.mobile-menu__container--icons .fa-times, .categories__container--list '
            ).removeClass('showed');
            $('.mobile-menu__container--icons .fa-bars').addClass('showed');
        }

        /**
         * @created by Zheko Hristov on 25/02/2022
         * @last-updated by Zheko Hristov on 25/02/2022
         *
         * Hide phone numbers container
         **/

        document.querySelector('.header__container--call').classList.remove('clicked');
        document.querySelector('.header__container--phone-numbers').classList.remove('showed');
    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

        /**
         * @created by Zheko Hristov on 15/10/2021
         * @last-updated by Zheko Hristov on 15/10/2021
         *
         * Main container min-height calc after adding bottom footer container
         **/

        // trigger on load
        mainContainerOuterMinHeightCalc();

        // resize event
        window.addEventListener('resize', function () {
            // trigger on resize
            mainContainerOuterMinHeightCalc();
            // do stuff here
        });

        function mainContainerOuterMinHeightCalc() {
            const mainContainer = document.querySelector('.main__container--outer');
            const bottomFooterContainer = document.querySelector('.footer__container--bottom');

            const windowHeight = window.innerHeight;
            const bottomFooterContainerHeight = bottomFooterContainer.clientHeight;
            const calculatedHeight = windowHeight - bottomFooterContainerHeight;

            mainContainer.style.minHeight = calculatedHeight + 'px';
        }

        /**
         * @created by Zheko Hristov on 10/08/2021
         * @last-updated by Zheko Hristov on 13/08/2021
         *
         * Mobile menu scripts
         **/

        const openMobileMenuIcon = document.querySelector(
            '.mobile-menu__container--icons .fa-bars'
        );
        const closeMobileMenuIcon = document.querySelector(
            '.mobile-menu__container--icons .fa-times'
        );
        const categoriesContainer = document.querySelector('.categories__container--list');

        // open mobile menu
        openMobileMenuIcon.addEventListener('click', function () {
            this.classList.remove('showed');
            closeMobileMenuIcon.classList.add('showed');
            categoriesContainer.classList.add('showed');
        });

        // close mobile menu
        closeMobileMenuIcon.addEventListener('click', function () {
            this.classList.remove('showed');
            openMobileMenuIcon.classList.add('showed');
            categoriesContainer.classList.remove('showed');
        });

        /**
         * @created by Zheko Hristov on 16/08/2021
         * @last-updated by Zheko Hristov on 17/08/2021
         *
         * Product details show full image container
         * and add zoom in and out functionality
         **/

        const detailsProductImageContainer = document.querySelector(
            '.product-details__container--image'
        );

        if (detailsProductImageContainer) {
            const detailsProductFullImageContainer = document.querySelector(
                '.product-details__container--full-image'
            );
            const detailsProductFullImageImage =
                detailsProductFullImageContainer.querySelector('img');
            const detailsProductFullImageCloseIcon = document.querySelector(
                '.product-details__container--full-image .fa-times'
            );
            const detailsProductFullImageZoomInOutIcon = document.querySelector(
                '.product-details__container--full-image .fa-search'
            );

            // zoom in and out function
            const fullImageZoomInOut = function fullImageZoomInOut() {
                detailsProductFullImageImage.classList.toggle('zoomed');
            };

            // show full image container
            detailsProductImageContainer.addEventListener('click', function () {
                // zoom the image with some delay
                detailsProductFullImageContainer.classList.add('showed');
                setTimeout(fullImageZoomInOut, 125);
            });

            // hide full image container
            detailsProductFullImageCloseIcon.addEventListener('click', function () {
                detailsProductFullImageContainer.classList.remove('showed');
                // zoom out the image
                detailsProductFullImageImage.classList.remove('zoomed');
            });

            // zoom in and out on click zoom icon
            detailsProductFullImageZoomInOutIcon.addEventListener('click', fullImageZoomInOut);
            // zoom in and out on click image
            detailsProductFullImageImage.addEventListener('click', fullImageZoomInOut);
        }

        /**
         * @created by Zheko Hristov on 10/08/2021
         * @last-updated by Zheko Hristov on 13/08/2021
         *
         * Contact us page show/hide offices
         **/

        const officeNameElements = document.querySelectorAll('.single-office__text--name');
        const officeNameElementsLength = officeNameElements.length;

        for (let i = 0; i < officeNameElementsLength; i++) {
            officeNameElements[i].addEventListener('click', function () {
                document
                    .querySelector('.single-office__text--name.selected')
                    .classList.remove('selected');
                document
                    .querySelector('.single-office__text--name:nth-of-type(' + (i + 1) + ')')
                    .classList.add('selected');

                document
                    .querySelector('.single-office__container--map.selected')
                    .classList.remove('selected');
                document
                    .querySelector('.single-office__container--map:nth-of-type(' + (i + 1) + ')')
                    .classList.add('selected');

                document
                    .querySelector('.single-office__container--details.selected')
                    .classList.remove('selected');
                document
                    .querySelector(
                        '.single-office__container--details:nth-of-type(' + (i + 1) + ')'
                    )
                    .classList.add('selected');
            });
        }

        /**
         * @created by Zheko Hristov on 25/02/2022
         * @last-updated by Zheko Hristov on 25/02/2022
         *
         * Open/close mobile phones container
         **/

        const callUsContainerLabel = document.querySelector('.header__container--call');
        const callUsContainerSubmenuPhoneNumbers = document.querySelector(
            '.header__container--phone-numbers'
        );

        callUsContainerLabel.addEventListener('click', function () {
            this.classList.toggle('clicked');
            callUsContainerSubmenuPhoneNumbers.classList.toggle('showed');
        });
    })();
});
