/**
 * @created by Zheko Hristov on 11/10/2024
 * @last-updated by Zheko Hristov on 14/10/2024
 *
 * Product filters page scripts
 **/

import noUiSlider from 'nouislider/dist/nouislider.min';

document.addEventListener('turbolinks:before-render', function () {
    (function () {
        'use strict';
    })();
});

document.addEventListener('turbolinks:load', function () {
    (function () {
        'use strict';

         /**
         * @created by Zheko Hristov on 14/10/2024
         * @last-updated by Zheko Hristov on 14/10/2024
         *
         * Sort jewelry
         **/

        // submit filter form on sort select change
        $('.js-jewelry-sort-by').on('change', function () {
            const thisSortOptionValue = $(this).val();
            $('.js-sort-by-option').val(thisSortOptionValue);
            $('.js-jewelry-filter-form').trigger('submit');
        });
        
        /**
         * @created by Zheko Hristov on 11/10/2024
         * @last-updated by Zheko Hristov on 11/10/2024
         *
         * Filter single section height function
         **/

        function filterSectionHeightCalculate(container) {
            const filterSectionContainer = container;
            const filterSectionTopContainer = filterSectionContainer.find('.filter-single-section__container--top');
            const filterSectionBottomContainer = filterSectionContainer.find('.filter-single-section__container--bottom');
            
            let calculatedBottomContainerHeight;
            const sectionHeight = filterSectionContainer.height();
            const topContainerHeight = filterSectionTopContainer.outerHeight(true);

            calculatedBottomContainerHeight = sectionHeight - topContainerHeight;
            filterSectionBottomContainer.height(calculatedBottomContainerHeight);
        }

        /**
         * @created by Zheko Hristov on 11/10/2024
         * @last-updated by Zheko Hristov on 14/10/2024
         *
         * Filter jewelry
         **/

        // open/close jewelry filters
        $('.js-toggle-jewelry-filters').on('click', function () {
            const toggleFiltersContainerButton = $(this);
            const filtersContainer = $('.jewelry__container--filters');

            toggleFiltersContainerButton.toggleClass('open');
            
            if (toggleFiltersContainerButton.hasClass('open')) {
                filtersContainer.addClass('showed');

            } else {
                filtersContainer.removeClass('showed');

            }

            $('.filter__container--section-labels > div, .filters__container--single-section')
                .removeClass('open');
            $('.filter__container--section-labels > div:nth-of-type(1), .filters__container--single-section:nth-of-type(1)')
                .addClass('open');
        });

        // open/close jewelry filters section
        $('.filter__container--section-labels').on('click', '> div', function () {
            const thisSectionLabelContainer = $(this);
            const thisSectionType = thisSectionLabelContainer.attr('data-section-type');
            $('.filter__container--section-labels > div, .filters__container--single-section')
                .removeClass('open');
            
            thisSectionLabelContainer.addClass('open');
            switch (thisSectionType) {
                case 'shop':
                    $('.filters__container--single-section.section__container--shop')
                        .addClass('open');
                    filterSectionHeightCalculate($('.filters__container--single-section.section__container--shop'));
                    break;
                case 'sizes':
                    $('.filters__container--single-section.section__container--sizes')
                        .addClass('open');
                    filterSectionHeightCalculate($('.filters__container--single-section.section__container--sizes'));
                    break;
                case 'length':
                    $('.filters__container--single-section.section__container--length')
                        .addClass('open');
                    filterSectionHeightCalculate($('.filters__container--single-section.section__container--length'));
                    break;
                case 'price':
                    $('.filters__container--single-section.section__container--price')
                        .addClass('open');
                        filterSectionHeightCalculate($('.filters__container--single-section.section__container--price'));
                    break;
            }
        });

        // range sliders
        if ($('.range-filter__container--slider').length > 0) {
            $('.range-filter__container--slider').each(function () {
                const rangeSlider = $(this);
                const rangeFilterTopContainer = rangeSlider.parents('.range-filter__container--top');
                const rangeFilterBottomContainer = rangeFilterTopContainer.siblings('.range-filter__container--bottom');

                const rangeSliderNativeDomElement = rangeSlider[0];

                const minValue = parseInt(rangeSlider.attr('data-min-value'));
                const maxValue = parseInt(rangeSlider.attr('data-max-value'));
                const lowerValue = parseInt(rangeSlider.attr('data-lower-value'));
                const higherValue = parseInt(rangeSlider.attr('data-higher-value'));

                if (maxValue === 0) {
                    rangeFilterTopContainer.addClass('container--disabled');
                }

                // fix back button issue - duplicates price range sliders
                if (rangeSlider.hasClass('noUi-target')) {
                    rangeSlider.find('.noUi-base:nth-of-type(1').remove();
                }

                noUiSlider.create(rangeSliderNativeDomElement, {
                    start: [lowerValue, higherValue],
                    connect: true,
                    step: 1,
                    range: {
                        min: minValue,
                        max: maxValue,
                    },
                    format: {
                        from: function (stringValue) {
                            return parseInt(stringValue);
                        },
                        to: function (numberValue) {
                            return Math.round(numberValue);
                        },
                    },
                });

                rangeSliderNativeDomElement.noUiSlider.on('update', function (values) {
                    // update visual lables
                    rangeFilterBottomContainer.find('.text--first-handle span').text(values[0]);
                    rangeFilterBottomContainer.find('.text--second-handle span').text(values[1]);

                    // update input values
                    rangeFilterBottomContainer.find('.js-range-lower-value').val('');
                    rangeFilterBottomContainer.find('.js-range-higher-value').val('');
                    if (minValue !== values[0] || maxValue !== values[1]) {
                        rangeFilterBottomContainer.find('.js-range-lower-value').val(values[0]);
                        rangeFilterBottomContainer.find('.js-range-higher-value').val(values[1]);
                    }
                });
            });
        }
    })();
});